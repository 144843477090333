import React, { useState } from 'react';
import './App.css';
import { FormControl, Button, InputGroup, Row, Col, Modal } from 'react-bootstrap';
import img from './cloud_upload-24px.svg';
import axios from 'axios';


class DropZone extends React.Component{
  constructor(props){
    super(props);
    this.state = { 
      hightlight: false 
    };
    this.fileInputRef = React.createRef();
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    this.fileInputRef.current.click();
  }
  onFilesAdded(evt) {
    const files = evt.target.files;
    const array = this.fileListToArray(files);
    this.uploadFile(array);
  }
  onDragOver(evt) {
    evt.preventDefault();
    this.setState({ hightlight: true });
  }
  onDragLeave() {
    this.setState({ hightlight: false });
  }
  onDrop(event) {
    event.preventDefault();
    const files = event.dataTransfer.files;
    const array = this.fileListToArray(files);
    this.uploadFile(array);
    this.setState({ hightlight: false });
  }
  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }
  uploadFile(array){
    if(this.props.number){

      var formData = new FormData();
      array.forEach((file,index) => {
        formData.append(`file${index}`,file);
      });
      formData.append('number', this.props.number);

      fetch('/back_new/add_file.php',{
        method: 'POST',
        body: formData
      })
      .then(response => response.json())
      .then(success => {
        alert(success);
        var files = [];
        fetch('/back_new/show_files.php?filenumber='+this.props.number)
        .then(response => response.json())
        .then(data => {
          data.forEach(item => {
            files.push(
              <InputGroup size="sm" className="my-1 mt-3">
                <FormControl
                  value={item['name']}
                  disabled
                />
                <InputGroup.Append>
                  <Button 
                    variant="outline-secondary" 
                    target="_blank" 
                    href={`/back_new/show_files.php?id=${item['id']}`}
                  >
                    Открыть
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            )
          })
          this.props.onDrop(files);
        })
      })
      .catch(error => console.log(error))


    }else{
      alert('Введите сначала номер телефона');
    }
  }

  render(){
    return(
      <div 
        className={`my-1 text-center Dropzone ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{ cursor: this.props.disabled ? "default" : "pointer" }}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          multiple
          onChange={this.onFilesAdded}
        />
        <img
          alt='upload'
          className='Icon'
          src={img}
        />
        <span>Перетащите файлы (Фото стр. паспорта с ФИО, с пропиской; Фото СТС лицевая и обратная стороны; Фото В/У лицевая и обратная стороны) сюда или кликните, чтобы выбрать и загрузить</span>
      </div>
    )
  }
}

class FetchFiles extends React.Component{
  constructor(props){
    super(props);
    this.chooseFiles = this.chooseFiles.bind(this)
  }

  chooseFiles(e){
    var files = [];
    var phone = e.target.value;
    fetch('back_new/show_files.php?filenumber='+phone)
      .then(response => response.json())
      .then(data => {
        data.forEach(item => {
          files.push(
            <InputGroup size="sm" className="my-1">
              <FormControl
                value={item['name']}
                disabled
              />
              <InputGroup.Append>
                <Button 
                  variant="outline-secondary" 
                  target="_blank" 
                  href={`/back_new/show_files.php?id=${item['id']}`}
                >
                  Открыть
                </Button>
              </InputGroup.Append>
            </InputGroup>
          )
        })
        this.props.onInput(files,phone);
      })
  }

  render(){
    return(
      <InputGroup className='mt-1'>
      <InputGroup.Prepend>
        <InputGroup.Text>Телефон: </InputGroup.Text>
        <InputGroup.Text>+7</InputGroup.Text>
      </InputGroup.Prepend>
        <FormControl
          type='text'
          placeholder={'8123456789'}
          onInput={this.chooseFiles}
        />
      </InputGroup>
    )
  }
}

function ModalInner({showMe,inner}) {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    window.location = 'redirect.html';
  };

  return (
    <div>
      <Modal show={show} onHide={showMe}>
        <Modal.Header closeButton>
          <Modal.Title>Ваша заявка</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: inner }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={showMe}>
            Исправить
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Отправить
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

class App extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      name: '',
      phone: '',
      city: '',
      code: Math.ceil(Math.random()*8) +""+ Math.ceil(Math.random()*4) +""+ Math.ceil(Math.random()*8) +""+ Math.ceil(Math.random()*3) +""+ Math.ceil(Math.random()*4) +""+ Math.ceil(Math.random()*2),
      codeChecked: false,
      agreeWithState: false,

      files: [],
      showResult: false,
      result: '',
      showAll: false,
      options: [],
      data: '',
      contract: ''

    }
    this.fetchOptions = this.fetchOptions.bind(this);
    this.refreshFileArray = this.refreshFileArray.bind(this);
    this.addToFileArrayAndSetNumber = this.addToFileArrayAndSetNumber.bind(this);
    this.giveMeCode = this.giveMeCode.bind(this);
    this.codeCheck = this.codeCheck.bind(this);
    this.handleInputs = this.handleInputs.bind(this);
    this.post = this.post.bind(this);
    this.setShow = this.setShow.bind(this)
  }

  refreshFileArray(files){
    this.setState({files: files});
  }
  addToFileArrayAndSetNumber(files, phone){
    this.setState({files: files, phone: phone});
  }
  handleInputs(event){
    this.setState({[event.target.name]: event.target.value});
    if(event.target.name === 'city'){
      this.setState({contract: this.state.data.city[event.target.value]})
    }
  }
  giveMeCode(){
    if(this.state.agreeWithState){
      if(this.state.phone){
        axios.post('/back_new/send.php', {
          phone: 7+this.state.phone, 
          generated: this.state.code
        })
        .then(function (response) {  
          alert(response.data);  
        })  
        .catch(function (error) {  
          console.log(error);  
          alert('что-то пошло не так, обратитесь к администратору');
        });
        
      }else{
        // console.log(this.state.code)
        alert('сначала введите номер телефона');
        // console.log(this.state)
      }
    }else{
      alert('сначала ознакомьтесь с договором, политикой конфиденциальности и обработкой персональных данных');
    }
  }

  codeCheck(event){
    if(event.target.value === this.state.code){
      event.target.classList.add('is-valid');
      event.target.classList.remove('is-invalid');
      this.setState({codeChecked: true});
      this.post('firstRow');
    }else{
      event.target.classList.remove('is-valid');
      event.target.classList.add('is-invalid');
      this.setState({codeChecked: false})
    }
  }

  post(row){
    var state = this.state;
    var self = this;
    const { name, phone, city } = state;

    switch(row){
      case 'firstRow':
        axios.post('/back_new/insert_to_db.php', {
          name: name,
          phone: phone,
          city: city,
          firstRow: true
        })
        .then(function (response) {
          if(response.data.length<130){
            // console.log(response.data);
          }else{
            alert('Приветствуем снова на нашем ресурсе, в всплывающем окне Вы можете ознакомится с данными которые уже занесены в базу данных, а также дополнить или изменить их. Просим обратить внимание, что в целях безопасности изменение номера телефона, ФИО, а также удаление файлов недоступны')
            self.setState({showResult:true, result: response.data, showAll:true});
          }
        })
        .catch(function (error) {
          console.log(error);
        });
        break;
      case 'all':
        axios.post('/back_new/insert_to_db.php', {
          phone: phone,
          all: true
        })
        .then(function (response) {
          // console.log(response.data);
          self.setState({showResult:true, result: response.data});

        })
        .catch(function (error) {
          console.log(error);
          alert('что-то пошло не так, обратитесь к администратору');
        });
        break;
      default:
        alert('Заполните необходимые поля');
        // console.log({state});
    }
  }

  setShow(){
    this.setState({showResult: false});
  }

  fetchOptions(){
    var options = [];
    fetch('/back_new/show_city.php')
      .then(response => response.json())
      .then(data => {
        Object.keys(data.city).forEach(item => {
          options.push(<option value={item}> {item} </option>);
        })
        this.setState({options: options, data: data})
      })
  }
  componentDidMount(){
    this.fetchOptions();
  }


  render(){
    return (
        <Col>

          <Row className='d-flex justify-content-center'>
            <Col lg={4} sm={6} xs={12}>
              <FormControl
                type='text'
                placeholder='ФИО...'
                name='name'
                onInput={this.handleInputs}
                className='mt-1'
              />
            </Col>
            <Col lg={4} sm={6} xs={12}>
              <FetchFiles onInput={this.addToFileArrayAndSetNumber}/>
            </Col>
          </Row>

          <Row className='d-flex justify-content-center'>
            <Col lg={8} xs={12}>
              <InputGroup className='mt-1'>
                <select name='city' className="custom-select" onChange={this.handleInputs}>
                  <option defaultValue>Выбрать город...</option>
                  {this.state.options}
                </select>
              </InputGroup>
            </Col>
          </Row>

          <Row className='d-flex justify-content-center'>
            <Col lg={8} xs={12}>
              <InputGroup size='lg' className='mt-1'>
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <input type="checkbox" name="agreeWithState" onChange={()=>this.setState({agreeWithState: !this.state.agreeWithState})} checked={this.state.agreeWithState}/>
                  </div>
                </div>
                <div type="text" className="form-control py-0" style={{fontSize: "12px"}}>С <a target="_blank" href={ this.state.contract?"new/select_from_system.php?id="+this.state.contract:''} rel="noopener noreferrer">Договором</a>, <a rel="noopener noreferrer" target="_blank" href="https://gruzovichec.ru/__privacy_policy">политикой конфиденциальности</a> и <a rel="noopener noreferrer" target="_blank" href="https://gruzovichec.ru/personalnih_dannih">обработкой персональных данных</a> ознакомлен и согласен</div>
              </InputGroup>
            </Col>
          </Row>

          <Row className='d-flex justify-content-center'>
            <Col lg={8} sm={12} xs={12}>
              <InputGroup className='mt-1'>
                <div className="input-group-prepend">
                  <button className="btn btn-primary" type="button" onClick={this.giveMeCode}>Получить смс с кодом</button>
                </div>
                <input type="text" className="form-control" placeholder="Введите код здесь..." onInput={this.codeCheck} />
              </InputGroup>
            </Col>
          </Row>
          
          <Row className='d-flex justify-content-center mb-3'>
            <Col xl={6} lg={6} xs={12}>

              {this.state.codeChecked?
                <DropZone number={this.state.phone} onDrop={this.refreshFileArray} />
              :""}

              {this.state.codeChecked?this.state.files:''}

              { this.state.codeChecked || this.state.showAll ?
                <Button className='mt-3' variant={this.state.codeChecked && this.state.agreeWithState ? 'success':'secondary'} onClick={this.state.codeChecked && this.state.agreeWithState ? ()=>this.post('all'):()=>alert('сначала верифицыруйте номер телефона, введите полученый проверочный код, а также ознакомьтесь с договором, политикой конфиденциальности и обработкой персональных данных')} block>Проверить</Button>
              :""}
              {this.state.showResult?
                <ModalInner showMe={this.setShow} inner={this.state.result} />
              :""}

            </Col>
          </Row>
        </Col>
    );
  }
}

export default App;
